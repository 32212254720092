import React, { Component } from 'react';
import './Navbar.css';


class Navbar extends Component {
  constructor() {
    super();
    this.state = {};
  }

  onClickLogout = (event) => {
    event.preventDefault();
    window.location.href = '/';
  }

  render() {
    return ( 
      <nav>
		<h2 className="logo-class">VPlaySmart</h2>
      </nav>
    );
  }
}

export default Navbar;
